import react from "react"
import Footer from "../components/Footer"
function Projects(){
    return(
        <>
 <div className="projects">
            <h1>Projects Again Now</h1>
        </div>
        <Footer/>
        </>
       
    )
}

export default Projects